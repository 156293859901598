import {Button, ButtonProps, Stack, styled} from "@mui/material";
import React, {ReactNode, useState} from "react";
import {ComponentFactory} from "@encoway/react-configurator";
import {addIndex, equals, length, lt, map} from "ramda";
import {ContainerTO} from "@encoway/c-services-js-client";
import {Parts} from "../../pages/parts/Parts";
import {PartsTable} from "../../pages/parts/partsTable/PartsTable";
import {DefaultButton} from "@fluentui/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useTranslation} from "react-i18next";
import {Part, PartDocuments} from "../../types/parts";

const StyledButton = styled(Button)(({theme}) => ({
  width: "228px",
  height: "55px",
  margin: 0,
  "&:not(:last-child)": {
    margin: "0 15px 0 0",
  },
  "&.MuiButton-outlined": {
    fontWeight: 600,
    borderBottom: "1px solid #FFF",
  },
}));

const items: Part[] = [
  {
    image: "/images/7000-78381-9780100.png",
    text1: "\"7/8'\"\" Bu. Flansch VWM\"",
    text2: "Litzen 5x0.75 1m",
    id: "7000-78381-9780100",
    articleNumber: "7000-78381-9780100",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  },
  {
    image: "/images/9000-11112-2062020.png",
    text1: "Emparro IP67 Schaltnetzteil 1-Phase ",
    text2: "OUT: 24VDC 8A - 7/8\" 5P - SELV",
    id: "9000-11112-2062020",
    articleNumber: "9000-11112-2062020",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  },
  {
    image: "/images/85440.png",
    text1: "Emparro Schaltnetzteil 1-phasig",
    text2: "IN: 100-240VAC OUT: 24-28VDC/5A",
    id: "85440",
    articleNumber: "85440",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  },
  {
    image: "/images/85133.JPG",
    text1: "Eco-Rail-2 Schaltnetzteil 1-phasig ",
    text2: "IN: 90 ... 132 VAC / 173 … 264 VAC OUT: 24V/5ADC",
    id: "85133",
    articleNumber: "85133",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  },
  {
    image: "/images/4000-76501-7651000.png",
    text1: "Signalsäule Modlight50 Pro bestückt mit LED-Modulen",
    text2: "weiß, blau, gruen, rot",
    id: "4000-76501-7651000",
    articleNumber: "4000-76501-7651000",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  },
  {
    image: "/images/7000-99104-0000000.png",
    text1: "\"Set Drehmomentschlüssel für 7/8\"\" SW 22\"",
    text2: "Drehmoment 1,5 Nm",
    id: "7000-99104-0000000",
    articleNumber: "7000-99104-0000000",
    assemblyPositions: [],
    quantity: 1,
    documents: null,
  }
]

export function CustomTabs({data, ...props}: any) {

  const {t} = useTranslation();

  const [selected, setSelected] = useState(0);
  const lastStep = equals(selected, data.children.length);

  console.log({data})

  const selectedViewPort = !lastStep && ComponentFactory.instanceOf(
    data.children[selected].viewPort!,
    {...props, data: data.children[selected]}
  )
  const nextStep = (current: number) =>
    lt(current, length(data.children)) ? current + 1 : 0;

  return (
    <Stack>
      <Stack style={{flexFlow: "row nowrap"}}>
        {data.children
        && addIndex<any>(map)((container: any, index: number) =>
            <StyledButton
              key={`conf-tab-${container.id}`}
              variant={equals(selected, index) ? "outlined" : "contained"}
              onClick={() => setSelected(index)}>
              <span>{container.translatedName}</span>
            </StyledButton>,
          data.children)}
      </Stack>
      <Stack style={{paddingBottom: "4rem"}}>{lastStep ? <PartsTable items={items} /> : selectedViewPort}</Stack>
      <Stack style={{flexFlow: "row nowrap", justifyContent: "flex-end"}}>
        {!lastStep && <Button size="large" variant="contained" onClick={lastStep ? () => null : () => setSelected(current => nextStep(current))}>
          {t("t:continue")}
          <ArrowForwardIosIcon sx={{fontSize: "1rem", paddingLeft: ".25em"}}/>
        </Button>}
      </Stack>
    </Stack>
  )
}
