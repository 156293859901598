import React from 'react';
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import {LoadConfiguration} from "./pages/configuration/LoadConfiguration";
import {Parts} from "./pages/parts/Parts";
import {Requirements} from "./pages/requirements/Requirements";
import {LanguageChange} from "./components/layout/LanguageChange";
import {Error500} from "./components/layout/Error500";
import {LoadRequirements} from './pages/requirements/LoadRequirements';
import {Provider} from "./Provider";
import {Layout} from "./components/layout/Layout";
import "./styleOverwrites.css";
import {LoadParts} from "./pages/parts/LoadParts";
import {PreConfiguration} from "./pages/requirements/preConfiguration/PreConfiguration";

function App() {
  console.log("Murr Vision POC")
  return (
    <Provider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="*" element={<Error500/>}/>
            <Route index element={<PreConfiguration />}/>
            <Route path="parts">
              <Route path=":id" element={<Parts/>}/>
              <Route path="new" element={<Parts/>}/>
              <Route index element={<LoadParts/>}/>
            </Route>
          </Route>
          <Route path="/requirements" element={<Layout showButtonRow={false}/>}>
            <Route path=":id" element={<Requirements/>}/>
            <Route index element={<Navigate to="/"/>}/>
          </Route>
          <Route path="/configuration" element={<Layout wrap={false}/>}>
            <Route path="pre">
              <Route path=":preId" element={<LoadConfiguration/>}/>
            </Route>
            <Route path="id">
              <Route path=":id" element={<LoadConfiguration/>}/>
            </Route>
            <Route path=":id" element={<LoadConfiguration/>}/>
            <Route index element={<LoadConfiguration/>}/>
          </Route>
        </Routes>
      </HashRouter>
    </Provider>
  );
}


export default App;
