import {Box, Grid, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {PreConfiguration} from "./preConfiguration/PreConfiguration";
import {Navigate, useParams} from "react-router-dom";
import {PATH} from "../../components/layout/tabs/useTabs";
import {PreConfigurationContext} from "../../context/usePreConfiguration";
import {ProductContext} from "../../context/useProduct";
import {FlexCentered} from "../../components/helpers/Flex";
import {HideImage} from "@mui/icons-material";

export function Requirements() {
  const {configurationId, load} = useContext(PreConfigurationContext);
  const {name, text, image} = useContext(ProductContext);
  const {id} = useParams();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id && !configurationId && !loading) {
      setLoading(true)
      load(id)
        .then(() => setLoading(false))
        .catch(() => setError(true));
    }
  }, [id, configurationId, loading, load]);

  if (error) {
    return <Navigate to={PATH.INDEX}/>
  }

  return <Grid spacing={4} container>
    <Grid sm={12} lg={5} item>
      <Box sx={{margin: "2em"}}>
        {image
          ? <FlexCentered sx={{height: "200px", background: image ? "initial" : "#00000010"}}>
            <img alt="vario-x" src={image} style={{width: "100%", maxWidth: "400px"}}/>
          </FlexCentered>
          : <FlexCentered sx={{background: "#00000010", height: "200px"}}>
            <HideImage/>
          </FlexCentered>
        }
      </Box>
      <Typography variant="h2" component="h2" sx={{margin: "1rem 0"}}>
        {name}
      </Typography>
      <div id="encoway-text" dangerouslySetInnerHTML={{__html: text}}/>
    </Grid>
    <Grid sm={12} lg={7} item>
      <PreConfiguration/>
    </Grid>
  </Grid>
}
