import {Box, styled, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";
import {HideImage} from "@mui/icons-material";
import {Flex, FlexCentered} from "../../../components/helpers/Flex";
import {map} from "ramda";
import {Part} from "../../../types/parts";
import {useTranslation} from "react-i18next";

const StyledSpan = styled(props => <Typography component="span" {...props} />)({
  "&:not(:last-child):after": {
    content: '", "',
  }
});

const StyledFlexCentered = styled(FlexCentered)(({theme}) => ({
  minWidth: "160px",
  height: "80px",
  background: theme.palette.contrast.main
}));

export function PartsTableRow({item}: { item: Part }) {
  const {t} = useTranslation();
  return <TableRow>
    <TableCell>
      <Flex alignItems="center">
        {item.image
          ? <img alt={item.text2} src={item.image} width="160" height="80" style={{objectFit: "contain"}} />
          : <StyledFlexCentered><HideImage/></StyledFlexCentered>
        }
        <Box display="flex" flexDirection="column" sx={{pl: "0.6rem", alignItems: "space-between"}}>
          <Typography variant="body1" display="block">{item.text1}</Typography>
          <Typography variant="body2" display="block">{item.text2}</Typography>
          <Typography variant="body2" display="block">{t("t:article_number")} {item.articleNumber}</Typography>
        </Box>
      </Flex>
    </TableCell>
    <TableCell align="center">
      {item.quantity}
    </TableCell>
  </TableRow>
}
