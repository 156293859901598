import React, {useCallback, useContext, useEffect} from "react";
import {ConfigurationContext} from "../../context/useConfiguration";
import {equals} from "ramda";
import {Progress} from "../../components/Progress";
import {PROGRESS, useProgress} from "../../hooks/useProgress";
import {PreConfigurationContext} from "../../context/usePreConfiguration";
import {Configuration} from "./Configuration";
import {ProductContext} from "../../context/useProduct";
import {Navigate, useParams} from "react-router-dom";
import {Modal} from "../../components/Modal";
import {Button, Typography} from "@mui/material";
import {Flex} from "../../components/helpers/Flex";
import {useTranslation} from "react-i18next";
import {PartsContext} from "../../context/useParts";

export function LoadConfiguration() {
  const {vis, loadRequirements, reload} = useContext(ConfigurationContext);
  const {selectedParameters, guiTO, preStart, start, configurationId, edited} = useContext(PreConfigurationContext);
  const products = useContext(ProductContext);
  const parts = useContext(PartsContext);
  const {notLoaded, loaded, set, error} = useProgress();
  const params = useParams<{ id?: string, preId?: string }>();
  const {t} = useTranslation();

  const onLoad = useCallback(async function () {
    if (guiTO && equals(guiTO.rootContainer.readyState, "READY")) {
      const selectedParamsIfReady = selectedParameters();
      return selectedParamsIfReady ? loadRequirements(selectedParamsIfReady) : set(PROGRESS.LOADED);
    }
    const product = await products.get("vision_conf");
    const conf = await preStart(product["vision_conf"]);
    const selectedParams = selectedParameters(conf.guiTO);
    return loadRequirements(selectedParams);
  }, [loadRequirements, selectedParameters, preStart, products, start, configurationId, guiTO, set]);

  function ifNotLoaded() {
    if (params.id) {
      reload(params.id)
          .then(() => set(PROGRESS.LOADED))
          .catch(() => set(PROGRESS.ERROR));
    } else if (parts.id && !edited) {
      reload(parts.id)
          .then(() => set(PROGRESS.LOADED))
          .catch(() => set(PROGRESS.ERROR));
    } else if (!vis || params.preId) {
      onLoad()
          .then(() => set(PROGRESS.LOADED))
          .catch(() => set(PROGRESS.ERROR));
    } else {
      set(PROGRESS.LOADED);
    }
  }

  useEffect(() => {
    if (notLoaded) {
      set(PROGRESS.LOADING);
      ifNotLoaded();
    }
  }, [notLoaded, onLoad, set, vis, params, params, edited]);

  if (!loaded) {
    return <Progress sx={{minHeight: "799px"}}/>
  }

  if (error) {
    return <Navigate to="/500"/>
  }

  return <>
    <Configuration/>
    <Modal
      open={error}
      close={() => set(PROGRESS.NOT_LOADED)}
      title={t("t:configuration_loading_error")}
    >
      <Typography sx={{marginBottom: ".25em"}}>{t("t:configuration_loading_error_text")}</Typography>
      <Flex sx={{justifyContent: "flex-end", margin: "1em 0 0 0"}}>
        <Button variant="contained" onClick={() => set(PROGRESS.NOT_LOADED)}>{t("t:ok")}</Button>
      </Flex>
    </Modal>
  </>
}
