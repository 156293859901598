import {Box, Button, Typography} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Flex} from "../../../components/helpers/Flex";
import {ForwardButton} from "../../../components/ForwardButton";
import {PreConfigurator} from "./PreConfigurator";
import {ResetButton} from "./ResetButton";
import {PreConfigurationContext} from "../../../context/usePreConfiguration";

export function PreConfiguration() {
  const {t} = useTranslation();
  const {configurationId, start} = useContext(PreConfigurationContext);

  useEffect(() => {
    console.log("here 46")
    start().then()
  }, [])

  return <Box>
    <Flex flexDirection="column" sx={{minHeight: "620px", overflow: "hidden", paddingTop: "0"}}>
      <Flex sx={{flex: 1}}>
        <PreConfigurator/>
      </Flex>
    </Flex>
  </Box>
}
