import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PartsTableRow} from "./PartsTableRow";
import {Part} from "../../../types/parts";
import {map} from "ramda";

export function PartsTable({items}: { items: Part[] }) {
  const {t} = useTranslation();

  return <TableContainer>
    <Table aria-label="partsList">
      <TableHead>
        <TableRow style={{textTransform: "uppercase"}}>
          <TableCell>{t("t:product")}</TableCell>
          <TableCell align="center">{t("t:quantity")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(part => (
          <PartsTableRow key={part.id} item={part}/>
        ), items)}
      </TableBody>
    </Table>
  </TableContainer>
}
