import React, {createContext, ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import {ConfigurationService, GuiTO, Http, MediaValue} from "@encoway/c-services-js-client";
import {Constants} from "@encoway/react-configurator";
import {L10n} from "@encoway/l10n";
import {useTranslation} from "react-i18next";
import {SETTINGS} from "../settings";
import {curry, equals, keys, map, mergeDeepRight, reduce, reject, slice} from "ramda";
import {Bus} from "baconjs";
import {translations as Tapp} from "@encoway/cui-application-components";
import {translations as Tconf} from "@encoway/cui-configurator-components";
import {anySelectionByUser, toPresetParameter, toSelectedParameters} from "./preConfigurationUtils";
import {ExtendedProduct} from "./useProduct";

export interface PreCfgState {
  cfg: ConfigurationService | undefined,
  guiTO: GuiTO | undefined
}

type PreConfigurationPromise = Promise<{
  cfg: ConfigurationService,
  guiTO: GuiTO
}>

export interface PreConfigurationState extends PreCfgState {
  start(productId?: string, salesContext?: SalesContext): PreConfigurationPromise,

  load(id: string): PreConfigurationPromise,

  preStart({characteristicValues}: ExtendedProduct): PreConfigurationPromise,

  selectedParameters(guiTO?: GuiTO): SelectedParameters,

  configurationId: string | undefined,
  language: "en-US" | string,
  eventBus: any,
  edited: boolean,
  ready: boolean | undefined,
}

interface SalesContext {
  characteristics: { [key: string]: string | number | MediaValue }
}

const initialStore: PreConfigurationState = {
  load: async function () {
    throw new Error("load not initialized")
  },
  start: async function () {
    throw new Error("start not initialized")
  },
  preStart: async function () {
    throw new Error("preStart not initialized")
  },
  selectedParameters: function () {
    throw new Error("selectedParameters not initialized")
  },

  language: "en-US",
  configurationId: undefined,
  guiTO: undefined,
  cfg: undefined,
  eventBus: undefined,
  edited: false,
  ready: false,
}

export interface SelectedParameters {
  [key: string]: string | number | undefined
}

export const PreConfigurationContext = createContext<PreConfigurationState>(initialStore);
export const PreConfigurationProvider = PreConfigurationContext.Provider;

const translations = mergeDeepRight(Tapp, Tconf);
const http = Http.Basic(SETTINGS.server.credentials.user, SETTINGS.server.credentials.password);
L10n.source("Configuration", translations, true);

export const LOCAL_STORAGE_CONFIGURATION = "configuration";

function usePreConfiguration(): PreConfigurationState {
  const {i18n} = useTranslation();
  const language = useMemo(() => slice(0, 2, i18n.language), [i18n.language])
  const eventBus = useMemo(() => new Bus(), []);
  const [subscriber, setSubscriber] = useState<NodeJS.Timer>();
  const [{cfg, guiTO}, setConfiguration] = useState<PreCfgState>({cfg: undefined, guiTO: undefined});
  const {edited, ready} = useMemo(() => ({
    edited: anySelectionByUser(guiTO?.rootContainer.children[0].parameters || []),
    ready: guiTO ? equals(guiTO.rootContainer.readyState, "READY") : undefined
  }), [guiTO]);

  const configurationId = useMemo(() => {
    if (cfg) {
      const id = cfg.id();
      sessionStorage.setItem(LOCAL_STORAGE_CONFIGURATION, id);
      return id;
    }
  }, [cfg]);

  const start = useCallback(async function (articleName?: string, salesContext?: SalesContext): PreConfigurationPromise {
    const configuration = await ConfigurationService.create(
      http,
      SETTINGS.server.baseUrl,
      {
        articleName: articleName || SETTINGS.requirements.articleName,
        ...salesContext && {salesContext}
      },
      i18n.language
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING"
      }
    });
    const config = {cfg: configuration, guiTO: await configuration.ui()};
    setConfiguration(config);
    return config;
  }, [setConfiguration, i18n.language]);

  const preStart = useCallback(async function ({characteristicValues}: ExtendedProduct): PreConfigurationPromise {
    const productReference = characteristicValues["product_reference"].values[0] as string;
    const characteristics = reject(name => equals(name, "product_reference"), keys(characteristicValues));
    const configuration = await start(productReference);
    const parameters = configuration.guiTO.rootContainer.children[0].parameters;
    const presetParameter = reduce(toPresetParameter(characteristics, characteristicValues), [], parameters)
    await Promise.all(map(({id, value}) => configuration.cfg.force(id, value as string), presetParameter));
    const config = {cfg: configuration.cfg, guiTO: await configuration.cfg.ui()};
    setConfiguration(config);
    return config;
  }, []);

  const load = useCallback(async function (confId: string): PreConfigurationPromise {
    const configuration = await ConfigurationService.create(
      http,
      SETTINGS.server.baseUrl,
      {configurationId: confId},
      i18n.language
    );
    configuration.settings({
      mappingOptions: {
        mappingProfile: "MAXIMUM_CONTENT_MAPPING"
      }
    });
    const config = {cfg: configuration, guiTO: await configuration.ui()};
    setConfiguration(config);
    return config
  }, [setConfiguration, i18n.language]);

  const selectedParameters = useCallback(function (_guiTO?: GuiTO): SelectedParameters {
    const internalGuiTO = _guiTO || guiTO;
    if (internalGuiTO && internalGuiTO.rootContainer.children[0]) {
      return reduce(toSelectedParameters, {}, internalGuiTO.rootContainer.children[0].parameters);
    }
    throw new Error("Could not get selected parameters. GuiTO is undefined");
  }, [guiTO]);

  useEffect(() => {
    return eventBus.onValue((e: any) => {
      const equalsEvent = curry(equals)(e.event);
      if (equalsEvent(Constants.Events.UpdateState)) {
        setConfiguration(prev => ({cfg: prev.cfg, guiTO: e.rawState as GuiTO}));
      }
    });
  }, [eventBus, setConfiguration]);

  useEffect(() => {
    if (cfg) {
      // Subs even if configurator isn't rendered
      const id = setInterval(() => cfg.status(), 3 * 60 * 1000);
      subscriber && clearInterval(subscriber);
      setSubscriber(id);
      return () => clearInterval(id);
    }
  }, [cfg]);

  useEffect(() => {
    L10n.reloadResources(i18n.language)
      .then(() => L10n.currentLocale(i18n.language));
  }, [i18n.language])

  return {
    configurationId,
    language,
    edited,
    ready,
    guiTO,
    cfg,
    start,
    preStart,
    load,
    selectedParameters,
    eventBus,
  };
}

export function PreConfigurationStore({children}: { children: ReactNode }) {
  return <PreConfigurationProvider value={usePreConfiguration()}>
    {children}
  </PreConfigurationProvider>
}
