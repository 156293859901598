import {pathOr} from "ramda";

export const SETTINGS = {
  LANGUAGES: {
    DE_DE: "de-DE",
    EN_US: "en-US"
  },
  FALLBACK_LANGUAGE: {
    tag: "en-US",
    displayName: "Deutsch"
  },
  server: {
    name: "default",
    baseUrl: pathOr<string>(process.env.REACT_APP_CPQ_BASE_URL || "http://showroom.murr-backend.docker-cpq4", ["encoway", "cpqBaseUrl"], window),
    credentials: {user: "xxx", password: "xxx"},
    ar: {
      baseUrl: "https://murr-ar-service.eu.encoway.cloud",
      retentionTime: 14
    },
    embedded: {
      baseUrl: "https://shop.murrelektronik.de/vario-x"
    },
    options: {
      rootGroup: "murr",
      configuration: {mappingOptions: {mappingProfile: "MAXIMUM_CONTENT_MAPPING"}}
    }
  },
  requirements: {
    articleName: "vision_conf"
  },
  shopProperties: {
    privacyPolicy: "https://shop.murrelektronik.de/en/Privacy-Policy/",
    legalNotice: "https://www.murrelektronik.com/index.php?eID=wes_download&hash=80ae27a65fbd946018649e6d9a684999204b9306",
  },
  configuration: {
    localStorageConfigurationName: "configurationName"
  },
  vc: {
    baseUrl: "https://visualization.encoway.cloud/vcs",
    version: "1.7.3",
    token:
      "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2aXN1YWxpemF0aW9uIiwiYXVkIjoiaHR0cHM6Ly93d3cubXVycmVsZWt0cm9uaWsuY29tLyIsImlzcyI6Im11cnJlbGVrdHJvbmlrIiwiaWF0IjoxNjQ3NDI5NDkwLCJleHAiOjE3MTE4NDMyMDAsImNhZCI6W10sInRvb2xzIjpudWxsLCJyZW5kZXJpbmciOiJzZCIsImFyIjoiMTQifQ.z_X8n6c9VXdHnpQ3J2HfrBqzRo-IECGcaX_PxjT6CtY",
    extensions: [
      { "name": "3d-exporter", "version": "1.7.3" },
    ],
    settings: {
      theme: {
        defaultFontStyle: {fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'},
        palette: {
          themePrimary: "rgb(81, 158, 26)",
          themeLighterAlt: "#f3fafd",
          themeLighter: "#d0eaf8",
          themeLight: "#a9d9f1",
          themeTertiary: "#3db60f",
          themeSecondary: "rgba(238, 249, 235, 1)",
          themeDarkAlt: "#37a40d",
          themeDark: "#37a40d",
          themeDarker: "rgb(104, 188, 47)",
          neutralLighterAlt: "#f8f8f8",
          neutralLighter: "#f4f4f4",
          neutralLight: "#eaeaea",
          neutralQuaternaryAlt: "#dadada",
          neutralQuaternary: "#d0d0d0",
          neutralTertiaryAlt: "#c8c8c8",
          neutralTertiary: "#bab8b7",
          neutralSecondary: "#a3a2a0",
          neutralPrimaryAlt: "#8d8b8a",
          neutralPrimary: "#323130",
          neutralDark: "#605e5d",
          black: "#494847",
          white: "#ffffff"
        }
      },
      features: {
        HighlightHoverItem: "off",
        Gizmo: "off",
        ContextMenu: "right",
        OrientationIndicator: "on"
      },
      ui: {
        contextMenu: {
          state: false,
          rotation_roll: false,
          rotation_flip: false
        },
        services: {
          cad: {
            baseUrl: "https://cad-gateway.encoway.cloud"
          }
        },
        loadingSpinner: {
          label: "Vario X"
        },
        grid: false,
        mode: {
          anchorOnly: true
        },
        catalog: {
          imageFit: "contain",
          productId: false,
          addButton: true,
          infoButton: true
        },
        contextActions: {
          zoom_first_item: true,
          open_configurator: false,
          open_text_editor: false
        },
        flow: null,
        background: "",
        lights: [
          {type: "ambient", name: "#AmbientLight", color: "#404040"},
          {type: "directional", position: {x: 2.6, y: 1, z: -3}, name: "#BackLight", intensity: 0.75},
          {type: "directional", position: {x: -2, y: -1, z: 1}, name: "#FrontLight", intensity: 0.6},
          {type: "directional", position: {x: 1, y: 4, z: 2}, name: "#TopFrontLight", intensity: 1.0}
        ],
        controls: {
          autozoom: false
        },
        sidebar: {
          type: "panel"
        },
        "toolbar": {
          "ViewIsometric": true,
          "ViewTop": true,
          "ViewBottom": false,
          "ViewFront": true,
          "ViewRear": false,
          "ViewLeft": false,
          "ViewRight": true,
          "select": false,
          "measure_distance": false,
          "dimensioning": false,
          "grid": false,
          "snap_objects": false,
          "configuration": true,
          "help": false,
          "autozoom": false
        },
        components: {
          catalog: "west",
          header: false,
          toolbar: "west"
        },
        anchors: {
          default: "#0077c7",
          ok: "#00c777",
          error: "#c70000"
        },
        configuration: {
          newComponents: true
        }
      }
    }
  }
}
