import {Box, Popover, PopoverOrigin, Typography} from "@mui/material";
import {ReactElement, ReactNode} from "react";
import {Theme} from "@emotion/react";
import {SxProps} from "@mui/system";

type DropdownModalProps = {
  headline?: string
  icon?: ReactElement<SVGRectElement>
  children?: ReactNode
  anchorEl: null | Element | ((element: Element) => Element),
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  open: boolean
  onClose?: () => void,
  sx?: SxProps<Theme>
};

export const DropdownModal = (props: DropdownModalProps) => {
  const {headline, icon, children, anchorEl, anchorOrigin, transformOrigin, open, onClose, sx} = props;

  return <Popover
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    open={open}
    onClose={onClose}
  >
    <Box sx={{p: "1.2rem", ...sx}}>
      {headline && <Typography display="flex" sx={{paddingBottom: ".6rem", gap: ".4rem"}}>
        {icon}
          <Typography component="span" sx={{textTransform: "uppercase"}}>{headline}</Typography>
      </Typography>}
      {children}
    </Box>
  </Popover>
}