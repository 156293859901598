import React, {createContext, ReactNode, useContext, useState} from "react";
import {generateQRCode} from "../service/arService";
import {pathOr} from "ramda";
import {exportSceneForAR, getRootNode} from "./aRCodeUtils";
import {ConfigurationContext} from "./useConfiguration";

export interface ARCodeState {
  code: string | undefined,

  generate(): Promise<string>,
}

export const ARCodeContext = createContext<ARCodeState>({
  code: undefined,
  generate: function () {
    throw new Error("generate not initialized")
  },
});

export const ARCodeProvider = ARCodeContext.Provider;

const arSettings = {
  placement: "floor",
  scaleFactor: 1,
}

function useARCode(): ARCodeState {
  const {vis} = useContext(ConfigurationContext);
  const [ARCode, setARCode] = useState<string | undefined>(undefined);

  async function generate(): Promise<string> {
    if (vis) {
      setARCode(undefined);
      const rootNode = getRootNode(vis.cloud.graph().nodes());
      if (rootNode) {
        const path = ["props", "product", "product", "characteristicValues"];
        const placement = pathOr(
          arSettings.placement,
          [...path, "ar-placement", "value"],
          rootNode
        );
        const scaleFactor = pathOr(
          arSettings.scaleFactor,
          [...path, "ar-scale-factor", "values", 0, "value"],
          rootNode
        )
        const glb = await exportSceneForAR(vis, placement);
        const newARCode = await generateQRCode(glb, scaleFactor, placement, vis.cloud.token);
        setARCode(newARCode);
        return newARCode;
      }
      throw new Error("Root node is not available, possible an empty visualization");
    }
    throw new Error("Configuration is not defined");
  }

  return {code: ARCode, generate}
}

export function ARCodeStore({children}: { children: ReactNode }) {
  return <ARCodeProvider value={useARCode()}>
    {children}
  </ARCodeProvider>
}
